import _ from "lodash"
import React from "react"
import { useMediaQuery } from "react-responsive"
import marked from "marked"
import { parseRawHtml as parse } from "../utils"

import {
  Accordion,
  Button,
  Card,
  Container,
  Header,
  Icon,
  Image,
  List,
  Modal,
  Menu,
  Popup,
  Segment,
  Sidebar,
  Item,
  Grid,
  Responsive
} from "semantic-ui-react"
import ResponsiveLayout from "../components/responsive-layout"
import SocialShare from "../components/social-share";
import "../components/styles.css"

import PageContent from "frontmatter-markdown-loader?modules!../../content/monitoring-and-accountability.md"
import { attributes as caseTopicsData } from "frontmatter-markdown-loader?modules!../../content/data/cases-by-topic.md"
import { attributes as caseResourcesAndDatabasesData } from "frontmatter-markdown-loader?modules!../../content/data/case-resources-and-databases.md"
import { attributes as interactiveMapsByTopicData } from "frontmatter-markdown-loader?modules!../../content/data/interactive-maps-by-topic.md"
import { attributes as noninteractiveMapsAndFiguresByTopicData } from "frontmatter-markdown-loader?modules!../../content/data/noninteractive-maps-and-figures-by-topic.md"

const interactiveMapsByTopic = interactiveMapsByTopicData.topics
const noninteractiveMapsAndFiguresByTopic = noninteractiveMapsAndFiguresByTopicData.topics
const caseTopics = caseTopicsData.caseTopics
const caseResourcesAndDatabases = caseResourcesAndDatabasesData.caseResourcesAndDatabases


export default ({ data = PageContent.attributes }) => (
  <ResponsiveLayout title={data.title}>
    <Page content={data} />
  </ResponsiveLayout>
)

const Page = ({ content, isMobile }) => {
  const isMobileSpecifically = useMediaQuery({
    maxWidth: Responsive.onlyMobile.maxWidth
  })
  const isTabletSpecifically = useMediaQuery({
    minWidth: Responsive.onlyTablet.minWidth,
    maxWidth: Responsive.onlyTablet.maxWidth,
  })
  const isComputerSpecifically = useMediaQuery({
    minWidth: Responsive.onlyTablet.maxWidth
  })
  return (

    <Container>

      <Segment basic style={{ marginTop: "4em", padding: "0em" }}>
        <Header as="h2" dividing>
          {content.introductionSection.title}
          <SocialShare config={content.introductionSection.social} float="right" />
        </Header>

        <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "3em" }}>
          {
            content.introductionSection.introduction &&
            parse(marked(content.introductionSection.introduction))
          }
        </Segment>
      </Segment>

      <Segment basic id="guides-and-resources" style={{ padding: "0em" }}>
        <Header as="h2" dividing style={{ marginTop: "3em", padding: "0em" }}>
          {content.monitoringGuidesAndAccountabilityResourcesSection.title}
          <SocialShare config={content.monitoringGuidesAndAccountabilityResourcesSection.social} float="right" />
        </Header>

        {
          content.monitoringGuidesAndAccountabilityResourcesSection.introduction &&
          <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "3em" }}>
            {parse(marked(content.monitoringGuidesAndAccountabilityResourcesSection.introduction))}
          </Segment>
        }

        {
          <Segment.Group style={{ marginTop: "3em" }}>
            {
              content.monitoringGuidesAndAccountabilityResourcesSection.subsections.map((subsection, i) => (
                <Segment padded>
                  <Header as="h3" >
                    {subsection.title}
                  </Header>
                  {
                    subsection.introduction &&
                    <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "4em" }}>
                      {parse(marked(subsection.introduction))}
                    </Segment>
                  }
                  {
                    subsection.items &&
                    <Item.Group relaxed
                      content={
                        subsection.items.map((item, j) => (
                          <Item style={{ marginTop: "4em" }}
                            children={
                              <>
                                <a target="_blank"
                                  style={{
                                    textAlign: "center"
                                  }}
                                  href={item.linkedFileSelfHosted || item.linkedFileOther || item.referenceLink}
                                  children={
                                    <Item.Image bordered spaced size="small"
                                      src={item.image}
                                    />
                                  }
                                />
                                {
                                  item.title && item.briefDescription &&
                                  <Item.Content
                                    style={{
                                      marginTop: isMobile && "2em",
                                      marginLeft: !isMobile && "1em",
                                    }}
                                    content={
                                      <>
                                        <Item.Header content={item.title} />
                                        <Item.Description className="MkdContent"
                                          content={
                                            item.briefDescription && parse(marked(item.briefDescription))
                                          }
                                        />
                                        <Item.Extra
                                          content={
                                            <Button primary as="a" target="_blank"
                                              href={item.linkedFileSelfHosted || item.linkedFileOther || item.referenceLink}
                                              content="Download"
                                            />
                                          }
                                        />
                                      </>
                                    }
                                  />
                                }
                              </>
                            }
                          />
                        ))
                      }
                    />
                  }
                </Segment>
              ))
            }
          </Segment.Group>
        }
      </Segment>

      <Segment basic id="data-for-making-the-case" style={{ marginTop: "5em", padding: "0em" }}>
        <Header as="h2" dividing>
          {content.dataForMakingTheCaseSection.title}
          <SocialShare config={content.dataForMakingTheCaseSection.social} float="right" />
        </Header>

        <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "2em" }}>
          {content.dataForMakingTheCaseSection.introduction && parse(marked(content.dataForMakingTheCaseSection.introduction))}
        </Segment>

        {
          content.dataForMakingTheCaseSection.itemsByOrganization.map((org) => (
            <>
              <Header as="h3"
                style={{
                  marginTop: "2em"
                }}
                content={org.organization.title}
              />
              {
                org.organization.items &&
                <Item.Group relaxed
                  content={
                    org.organization.items.map((item, j) => (
                      <Item style={{ marginTop: "2em" }}
                        children={
                          <>
                            <a target="_blank"
                              style={{
                                textAlign: "center"
                              }}
                              href={item.linkedFileSelfHosted || item.linkedFileOther || item.referenceLink}
                              children={
                                <Item.Image bordered spaced size="small"
                                  src={item.image}
                                />
                              }
                            />
                            {
                              item.title && item.briefDescription &&
                              <Item.Content
                                style={{
                                  marginTop: isMobile && "2em",
                                  marginLeft: !isMobile && "1em",
                                }}
                                content={
                                  <>
                                    <Item.Header style={{
                                      color: "unset",
                                      textDecoration: "underline",
                                    }}>
                                      <a href={item.referenceLink} target="_blank">
                                        {item.title}
                                      </a>
                                    </Item.Header>
                                    <Item.Description className="MkdContent"
                                      content={
                                        parse(marked(item.briefDescription))
                                      }
                                    />
                                  </>
                                }
                              />
                            }
                          </>
                        }
                      />
                    ))
                  }
                />
              }
            </>
          ))
        }

        <Header as="h3"
          style={{ marginTop: "4em" }}
          content="Maps and Figures"
        />

        {
          isMobile ?
            <Mapsv4 isMobile={isMobile}
              isMobileSpecifically={isMobileSpecifically}
              isTabletSpecifically={isTabletSpecifically}
              isComputerSpecifically={isComputerSpecifically}
            />
            : <Mapsv5 isMobile={isMobile}
              isMobileSpecifically={isMobileSpecifically}
              isTabletSpecifically={isTabletSpecifically}
              isComputerSpecifically={isComputerSpecifically}
            />
        }
      </Segment>

      <Segment basic style={{ marginTop: "4em", padding: "0em" }}>

        <Header as="h3">
          {content.constitutionalCasesFromAroundTheWorldSection.title}
        </Header>

        <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "2em" }}>
          {parse(marked(content.constitutionalCasesFromAroundTheWorldSection.introduction))}
        </Segment>

        <Segment basic style={{ padding: "0em" }}>
          <ConstitutionalCasesV2 data={content} isMobile={isMobile} />
        </Segment>

      </Segment>

      <Segment basic id="citizens-play-an-important-role" style={{ marginTop: "5em", padding: "0em" }}>

        <Header as="h2" dividing>
          {content.citizensPlayAnImportantRoleSection.title}
          <SocialShare config={content.citizensPlayAnImportantRoleSection.social} float="right" />
        </Header>

        <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "2em" }}>
          {parse(marked(content.citizensPlayAnImportantRoleSection.introduction))}
        </Segment>

        {
          content.citizensPlayAnImportantRoleSection.items &&
          <Segment basic>
            <Item.Group relaxed
              content={
                content.citizensPlayAnImportantRoleSection.items.map((item, j) => (
                  <Item style={{ marginTop: "4em" }}
                    children={
                      <>
                        <a target="_blank"
                          style={{
                            textAlign: "center"
                          }}
                          href={item.linkedFileSelfHosted || item.linkedFileOther || item.referenceLink}
                          children={
                            <Item.Image bordered spaced size="small"
                              src={item.image}
                            />
                          }
                        />
                        {
                          item.title && item.briefDescription &&
                          <Item.Content
                            style={{
                              marginTop: isMobile && "2em",
                              marginLeft: !isMobile && "1em",
                            }}
                            content={
                              <>
                                <Item.Header content={item.title} />
                                <Item.Description className="MkdContent"
                                  content={
                                    item.briefDescription && parse(marked(item.briefDescription))
                                  }
                                />
                                <Item.Extra
                                  content={
                                    <Button primary as="a" target="_blank"
                                      href={item.linkedFileSelfHosted || item.linkedFileOther || item.referenceLink}
                                      content="Download"
                                    />
                                  }
                                />
                              </>
                            }
                          />
                        }
                      </>
                    }
                  />
                ))
              }
            />
          </Segment>
        }

      </Segment>

      <Segment basic id="initiatives-and-organizations-of-network-members" style={{ padding: "0em" }}>

        <Header as="h2" dividing style={{ marginTop: "3em" }}>
          {content.citizensPlayAnImportantRoleSection.networkInitiativesAndOrganizationsSubsection.title}
          <SocialShare config={content.citizensPlayAnImportantRoleSection.networkInitiativesAndOrganizationsSubsection.social} float="right" />
        </Header>

        <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "2em", marginBottom: "2em" }}>
          {parse(marked(content.citizensPlayAnImportantRoleSection.networkInitiativesAndOrganizationsSubsection.introduction))}
        </Segment>

        {
          false &&
          <List horizontal relaxed style={{}}>
            {
              content.citizensPlayAnImportantRoleSection.networkInitiativesAndOrganizationsSubsection.informationDirectory
                .map((item, i) => (
                  item.image &&
                  <Popup
                    trigger={
                      <List.Item>
                        <Image avatar bordered
                          src={item.image}
                          as="a" href={item.referenceLink} target="_blank"
                        />
                      </List.Item>
                    }
                    content={item.title}
                  />
                ))
            }
          </List>
        }

        <InformationDirectory isMobile={isMobile} informationDirectory={content.citizensPlayAnImportantRoleSection.networkInitiativesAndOrganizationsSubsection.informationDirectory} />

      </Segment>

    </Container>

  )
}

class InformationDirectory extends React.Component {
  state = {
    activeIndices: {
      0: true
    }
  }
  toggleItemVisibility = (e, titleProps) => {
    if (this.state.activeIndices[titleProps.index]) {
      delete this.state.activeIndices[titleProps.index]
    }
    else {
      this.state.activeIndices[titleProps.index] = true
    }
    this.setState({
      activeIndices: this.state.activeIndices
    })
  }
  render() {
    return (
      <Accordion fluid styled style={{ marginTop: "3em" }}>
        {this.props.informationDirectory.map((item, i) => (
          <>
            <Accordion.Title
              index={i}
              // active={!!this.state.activeIndices[i]}
              active={true}
              onClick={this.toggleItemVisibility}
              style={{ fontSize: "1em" }}
            >
              <Icon name="dropdown" />
              {item.title}
            </Accordion.Title>
            <Accordion.Content active={!!this.state.activeIndices[i]}>
              <Segment basic style={{}}>
                <Image bordered centered={this.props.isMobile} rounded
                  src={item.image} size="small"
                  floated={!this.props.isMobile && "left"}
                  style={{ marginBottom: this.props.isMobile && "2em" }}
                />
                <Segment basic className="MkdContent" style={{ margin: "0em", padding: "0em", overflow: "hidden" }}>
                  {item.briefDescription && parse(marked(item.briefDescription))}
                </Segment>
              </Segment>
            </Accordion.Content>
          </>
        ))}
      </Accordion>
    )
  }
}

class ConstitutionalCasesV2 extends React.Component {
  state = {
    isCaseTopicsSubsectionActive: true,
    isCaseResourcesAndDatabasesSubsectionActive: false,
    activeCaseTopicIndices: {
    },
    activeCaseResourcesAndDatabasesIndices: {
    }
  }
  toggleActiveCaseTopics = (e, titleProps) => {
    if (this.state.activeCaseTopicIndices[titleProps.index]) {
      delete this.state.activeCaseTopicIndices[titleProps.index]
    }
    else {
      this.state.activeCaseTopicIndices[titleProps.index] = true
    }
    this.setState({
      activeCaseTopicIndices: this.state.activeCaseTopicIndices
    })
  }
  toggleActiveCaseResourcesAndDatabases = (e, titleProps) => {
    if (this.state.activeCaseResourcesAndDatabasesIndices[titleProps.index]) {
      delete this.state.activeCaseResourcesAndDatabasesIndices[titleProps.index]
    }
    else {
      this.state.activeCaseResourcesAndDatabasesIndices[titleProps.index] = true
    }
    this.setState({
      activeCaseResourcesAndDatabasesIndices: this.state.activeCaseResourcesAndDatabasesIndices
    })
  }
  render() {
    return (
      <Accordion fluid styled style={{ marginTop: "3em" }}>
        <Accordion.Title
          // active={this.state.isCaseTopicsSubsectionActive}
          active={true}
          onClick={() => this.setState({
            isCaseTopicsSubsectionActive: !this.state.isCaseTopicsSubsectionActive
          })}
          style={{ fontSize: "1.1em" }}
        >
          <Icon name="dropdown" />
          {this.props.data.constitutionalCasesFromAroundTheWorldSection.caseTopicsSubsection.title}
        </Accordion.Title>
        <Accordion.Content
          active={this.state.isCaseTopicsSubsectionActive}
        >
          <Segment basic padded={!this.props.isMobile} style={{ paddingLeft: this.props.isMobile && "0em", paddingRight: this.props.isMobile && "0em" }}>
            <Segment basic className="MkdContent" style={{ padding: "0em" }}>
              {
                this.props.data.constitutionalCasesFromAroundTheWorldSection.caseTopicsSubsection.image &&
                <Image rounded bordered floated="left" size="tiny"
                  src={this.props.data.constitutionalCasesFromAroundTheWorldSection.caseTopicsSubsection.image}
                />
              }
              {
                this.props.data.constitutionalCasesFromAroundTheWorldSection.caseTopicsSubsection.introduction &&
                parse(marked(this.props.data.constitutionalCasesFromAroundTheWorldSection.caseTopicsSubsection.introduction))
              }
            </Segment>
            {
              !this.props.isMobile && <CaseTopics />
            }
            {
              this.props.isMobile &&
              <Segment basic style={{ padding: "0em" }}>
                <Accordion fluid styled style={{ marginTop: "3em" }}>
                  {caseTopics.map((item, i) => (
                    <>
                      <Accordion.Title
                        index={i}
                        // active={!!this.state.activeCaseTopicIndices[i]}
                        active={true}
                        onClick={this.toggleActiveCaseTopics}
                        style={{ fontSize: "1.1em" }}
                      >
                        <Icon name="dropdown" />
                        {item.caseTopic.title}
                      </Accordion.Title>
                      <Accordion.Content
                        active={!!this.state.activeCaseTopicIndices[i]}
                      >
                        <Segment basic className="MkdContent"
                          style={{
                            paddingLeft: this.props.isMobile && "0em",
                            paddingRight: this.props.isMobile && "0em",
                            overflow: "auto",
                          }}
                          children={
                            <>
                              <Image rounded bordered floated="right" size="medium"
                                src={item.caseTopic.image}
                              />
                              {item.caseTopic.cases && parse(marked(item.caseTopic.cases))}
                            </>
                          }
                        />
                      </Accordion.Content>
                    </>
                  ))}
                </Accordion>
              </Segment>
            }
          </Segment>
        </Accordion.Content>
        <Accordion.Title
          // active={this.state.isCaseResourcesAndDatabasesSubsectionActive}
          active={true}
          onClick={() => this.setState({
            isCaseResourcesAndDatabasesSubsectionActive: !this.state.isCaseResourcesAndDatabasesSubsectionActive
          })}
          style={{ fontSize: "1.1em" }}
        >
          <Icon name="dropdown" />
          {this.props.data.constitutionalCasesFromAroundTheWorldSection.caseResourcesAndDatabasesSubsection.title}
        </Accordion.Title>
        <Accordion.Content
          active={this.state.isCaseResourcesAndDatabasesSubsectionActive}
        >
          <Segment basic padded={!this.props.isMobile} style={{ paddingLeft: this.props.isMobile && "0em", paddingRight: this.props.isMobile && "0em" }}>
            <Segment basic className="MkdContent" style={{ padding: "0em" }}>
              {
                this.props.data.constitutionalCasesFromAroundTheWorldSection.caseResourcesAndDatabasesSubsection.image &&
                <Image rounded bordered floated="left" size="tiny"
                  src={this.props.data.constitutionalCasesFromAroundTheWorldSection.caseResourcesAndDatabasesSubsection.image}
                />
              }
              {
                this.props.data.constitutionalCasesFromAroundTheWorldSection.caseResourcesAndDatabasesSubsection.introduction &&
                parse(marked(this.props.data.constitutionalCasesFromAroundTheWorldSection.caseResourcesAndDatabasesSubsection.introduction))
              }
            </Segment>
            <Segment basic style={{ padding: "0em" }}>
              <Accordion fluid styled style={{ marginTop: "3em" }}>
                {caseResourcesAndDatabases.map((item, i) => (
                  <>
                    <Accordion.Title
                      index={i}
                      // active={!!this.state.activeCaseResourcesAndDatabasesIndices[i]}
                      active={true}
                      onClick={this.toggleActiveCaseResourcesAndDatabases}
                      style={{ fontSize: "1.1em" }}
                    >
                      <Icon name="dropdown" />
                      {item.title}
                    </Accordion.Title>
                    <Accordion.Content
                      active={!!this.state.activeCaseResourcesAndDatabasesIndices[i]}
                    >
                      <Segment basic
                        className="MkdContent"
                        style={{
                          paddingLeft: this.props.isMobile && "0em",
                          paddingRight: this.props.isMobile && "0em",
                          overflow: "auto",
                        }}
                        children={
                          <>
                            <Image rounded bordered floated="right" size="medium"
                              src={item.image}
                            />
                            {item.briefDescription && parse(marked(item.briefDescription))}
                          </>
                        }
                      />
                    </Accordion.Content>
                  </>
                ))}
              </Accordion>
            </Segment>
          </Segment>
        </Accordion.Content>
      </Accordion >
    )
  }
}

class CaseTopics extends React.Component {
  state = {
    contentSegment: this.renderContentSegment(caseTopics[0].caseTopic, 0)
  }
  toggleTopicVisibility = (caseTopic, index) => () => {
    this.setState({
      contentSegment: this.renderContentSegment(caseTopic, index)
    })
  }
  renderContentSegment(caseTopic, index) {
    return (
      <Segment padded style={{ height: "40em", overflow: "auto" }}>
        <Header as="h3" dividing textAlign="center">{caseTopic.title}</Header>
        <Segment basic className="MkdContent"
          style={{
            paddingLeft: this.props.isMobile && "0em",
            paddingRight: this.props.isMobile && "0em",
          }}
          children={
            <>
              <Image rounded bordered floated="right" size="medium"
                src={caseTopic.image}
              />
              {caseTopic.cases && parse(marked(caseTopic.cases))}
            </>
          }
        />
      </Segment >
    )
  }
  render() {
    return (
      <Sidebar.Pushable as={Grid}
        style={{
          margin: "0em"
        }}
        children={
          <>
            <Grid.Column width={2}
              style={{
                padding: "0em",
              }}
              children={
                <Sidebar
                  as={Menu}
                  animation="push"
                  icon="labeled"
                  inverted
                  vertical
                  visible
                  width="thin"
                  style={{
                    backgroundColor: "cornflowerblue",
                    position: "unset",
                  }}
                  content={
                    caseTopics.map((item, i) => (
                      <Menu.Item as="a"
                        onClick={this.toggleTopicVisibility(item.caseTopic, i)}
                        style={{ lineHeight: "1.5em", textAlign: "left" }}
                        content={item.caseTopic.title}
                      />
                    ))
                  }
                />
              }
            />
            <Grid.Column width={14}
              style={{
                padding: "0em",
                paddingLeft: "2em",
              }}
              children={
                <Sidebar.Pusher
                  content={this.state.contentSegment}
                />
              }
            />
          </>
        }
      />
    )
  }
}

class Mapsv5 extends React.Component {
  state = {
    contentSegment: this.renderContentSegment(interactiveMapsByTopic[0], 0, noninteractiveMapsAndFiguresByTopic[0])
  }
  toggleTopicVisibility = (topic, index, itemTopic) => () => {
    this.setState({
      contentSegment: this.renderContentSegment(topic, index, itemTopic)
    })
  }
  renderContentSegment(topic, index, itemTopic) {
    return (
      <Segment padded style={{ overflow: "auto", height: "40em" }}>
        <Header as="h3" dividing textAlign="center">{topic.topic.title}</Header>
        <Segment basic>
          <Card.Group itemsPerRow={3} style={{ margin: "0em" }}>
            {topic.topic.interactiveMaps.map(interactiveMap => (
              <Card>
                <Modal closeIcon={true} size="large"
                  trigger={
                    <Image as="a" src={interactiveMap.image} wrapped ui={false} />
                  }
                  children={
                    <>
                      {/* <Modal.Header>
                        {interactiveMap.title}
                        {
                          interactiveMap.referenceLink &&
                          <a href={interactiveMap.referenceLink} target="_blank" style={{ marginLeft: "1em" }}>
                            <Icon size="small" name="external" />
                          </a>
                        }
                      </Modal.Header> */}
                      <Modal.Content>
                        <Segment raised textAlign="center"
                          className={
                            (this.props.isComputerSpecifically && "embed-container")
                            || (this.props.isTabletSpecifically && "tablet-embed-container")
                            || (this.props.isMobileSpecifically && "mobile-embed-container")
                          }
                          style={{
                            marginTop: this.props.isMobileSpecifically && "3em"
                          }}
                          children={parse(interactiveMap.embedTag)}
                        />
                        <Modal.Description>
                          <Segment basic className="MkdContent" style={{ marginTop: "2em", padding: "0em" }}>
                            {interactiveMap.briefDescription && parse(marked(interactiveMap.briefDescription))}
                          </Segment>
                        </Modal.Description>
                      </Modal.Content>
                    </>
                  }
                />
                <Card.Content textAlign="center"
                  style={{
                    marginTop: "1em",
                    borderTop: "0em"
                  }}
                  content={interactiveMap.title}
                />
              </Card>
            )).concat((itemTopic.topic.noninteractiveMapsAndFigures || []).map(item => (
              <Card>
                <Modal closeIcon={true} size="large"
                  trigger={
                    <Image as="a" src={item.image} wrapped ui={false} />
                  }
                  children={
                    <>
                      {/* <Modal.Header>
                        {item.title}
                        {
                          item.referenceLink &&
                          <a href={item.referenceLink} target="_blank" style={{ marginLeft: "1em" }}>
                            <Icon size="small" name="external" />
                          </a>
                        }
                      </Modal.Header> */}
                      <Modal.Content>
                        <Segment raised textAlign="center">
                          <Image bordered centered rounded size="huge" src={item.image} />
                        </Segment>
                        <Modal.Description>
                          <Segment basic className="MkdContent" style={{ marginTop: "2em", padding: "0em" }}>
                            {item.briefDescription && parse(marked(item.briefDescription))}
                          </Segment>
                        </Modal.Description>
                      </Modal.Content>
                    </>
                  }
                />
                <Card.Content textAlign="center"
                  style={{
                    marginTop: "1em",
                    borderTop: "0em",
                  }}
                  content={item.title}
                />
              </Card>
            )))}
          </Card.Group>
        </Segment>
      </Segment>
    )
  }
  render() {
    return (
      <Sidebar.Pushable as={Grid}
        style={{
          margin: "0em",
          marginTop: "2em",
        }}
        children={
          <>
            <Grid.Column width={2}
              style={{
                padding: "0em",
              }}
              children={
                <Sidebar
                  as={Menu}
                  animation="push"
                  icon="labeled"
                  inverted
                  vertical
                  visible
                  width="thin"
                  style={{
                    backgroundColor: "cadetblue",
                    position: "unset",
                  }}
                  content={
                    interactiveMapsByTopic.map((topic, i) => (
                      <Menu.Item as="a"
                        onClick={this.toggleTopicVisibility(topic, i, noninteractiveMapsAndFiguresByTopic[i])}
                        style={{ lineHeight: "1.5em", textAlign: "left" }}
                        content={topic.topic.title}
                      />
                    ))
                  }
                />
              }
            />
            <Grid.Column width={14}
              style={{
                padding: "0em",
                paddingLeft: "2em",
              }}
              children={
                <Sidebar.Pusher
                  content={this.state.contentSegment}
                />
              }
            />
          </>
        }
      />
    )
  }
}

class Mapsv4 extends React.Component {
  state = {
    activeTopicIndices: {
      0: true
    }
  }
  toggleTopicVisibility = (e, titleProps) => {
    if (this.state.activeTopicIndices[titleProps.index]) {
      delete this.state.activeTopicIndices[titleProps.index]
    }
    else {
      this.state.activeTopicIndices[titleProps.index] = true
    }
    this.setState({
      activeTopicIndices: this.state.activeTopicIndices
    })
  }
  render = () => {
    return (
      <Accordion fluid styled
        style={{ marginTop: "2em" }}
        children={
          interactiveMapsByTopic.map((item, i) => (
            <>
              <Accordion.Title
                index={i}
                // active={!!this.state.activeTopicIndices[i]}
                active={true}
                onClick={this.toggleTopicVisibility}
                icon="dropdown"
                content={item.topic.title}
              />
              <Accordion.Content
                active={!!this.state.activeTopicIndices[i]}
                children={
                  <Segment basic padded={!this.props.isMobile}>
                    <Card.Group stackable itemsPerRow={3} style={{ marginTop: this.props.isMobile && "1em" }}>
                      {
                        item.topic.interactiveMaps
                          .concat(noninteractiveMapsAndFiguresByTopic[i].topic.noninteractiveMapsAndFigures || [])
                          .map(mapOrFig => (
                            <Card centered style={{ padding: "1em", marginTop: "2em" }}>
                              <Modal closeIcon={true} size="large"
                                trigger={
                                  <a>
                                    <Image bordered centered rounded src={mapOrFig.image} style={{ height: !this.props.isMobile && "15em" }} />
                                  </a>
                                }
                                children={
                                  <>
                                    {/* <Modal.Header>
                                    {mapOrFig.title}
                                    {
                                      mapOrFig.referenceLink &&
                                      <a href={mapOrFig.referenceLink} target="_blank" style={{ marginLeft: "1em" }}>
                                        <Icon size="small" name="external" />
                                      </a>
                                    }
                                  </Modal.Header> */}
                                    <Modal.Content>
                                      {
                                        (
                                          mapOrFig.embedTag &&
                                          <Segment raised textAlign="center"
                                            className={
                                              (this.props.isComputerSpecifically && "embed-container")
                                              || (this.props.isTabletSpecifically && "tablet-embed-container")
                                              || (this.props.isMobileSpecifically && "mobile-embed-container")
                                            }
                                            style={{
                                              marginTop: this.props.isMobileSpecifically && "3em"
                                            }}
                                            children={parse(mapOrFig.embedTag)}
                                          />
                                        ) || (
                                          <Segment raised textAlign="center"
                                            style={{
                                              marginTop: this.props.isMobileSpecifically && "3em"
                                            }}
                                            children={
                                              <Image bordered centered rounded size="huge" src={mapOrFig.image} />
                                            }
                                          />
                                        )
                                      }
                                      <Modal.Description>
                                        <Segment basic className="MkdContent" style={{ marginTop: "2em", paddingLeft: "0em" }}>
                                          {mapOrFig.briefDescription && parse(marked(mapOrFig.briefDescription))}
                                        </Segment>
                                      </Modal.Description>
                                    </Modal.Content>
                                  </>
                                }
                              />
                              <Card.Content textAlign="center"
                                style={{
                                  borderTop: "0em",
                                }}
                                content={mapOrFig.title}
                              />
                            </Card>
                          ))
                      }
                    </Card.Group>
                  </Segment>
                }
              />
            </>
          ))
        }
      />
    )
  }
}
